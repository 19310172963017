@import '@angular/material/theming';

// Include non-theme styles for core.
@include mat-core();

// salonMonster colors
$white: #fff;
$black: #222;
$pale: #999;
$faint: #ddd;
$sm-green: #07b6b7;


$smc-palette: (
  0: #ffffff,
  50: #46f7f8,
  100: #2df6f7,
  200: #14f5f6,
  300: #09e7e8,
  400: #08ced0,
  500: #07b6b7,
  600: #069e9e,
  700: #058586,
  800: #046d6d,
  900: #035455,
  A100: #5ef8f9,
  A200: #77f9fa,
  A400: #8ffafb,
  A700: #023c3c,
  contrast: (
    50: #b3b3b3,
    100: #bfbfbf,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #f2f2f2,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #fff,
    A400: #f2f2f2,
    A700: #ffffff
  )
);


// Define a theme.
$primary: mat-palette($smc-palette, 400, 50 ,400);
$accent:  mat-palette($smc-palette, 50, 700, 700);

$theme: mat-light-theme($primary, $accent);

// Include all theme styles for the components.
@include angular-material-theme($theme);

// Reset the body padding and color
body, html{
  padding: 0;
  margin: 0;
  background-color: #fff;
  line-height: 1.5em;
  font-family: Roboto,"Helvetica Neue",sans-serif;
  font-weight: 400;
}

textarea.mat-input-element {
  padding: 5px;
  margin: 4px 0;
  border: 1px solid #ccc;
  background: #f9f9f9;
}

.mat-snack-bar-container.error-snackbar {
  background-color:red !important;
  color: #fff !important;
}

.mat-snack-bar-container.error-snackbar .mat-simple-snackbar-action {
  color: #fff !important;
}