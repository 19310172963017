/* You can add global styles to this file, and also import other style files */
/*@import '~@angular/material/core/theming/prebuilt/deeppurple-amber.css';*/

html, body {
	background-color: transparent;
}

.sm-login-tabs .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display:none !important;
}

.sm-login-tabs .mat-tab-label {
	max-width: 50%;
	width:50%;
	min-width: 50%;
}

textarea.mat-input-element {
    /*border: 1px solid #ccc;*/
    padding: 8px;
    margin: .25em 0 0 0;
    width: 100%;
    box-sizing: border-box;
}

.smc-button-large, .smc-button-large.mat-raised-button {
	font-size: 24px;
	font-weight: 400;
	text-transform: uppercase;
}

.smc-icon-large {
	font-size: 60px;
	width: 60px;
	height: 60px;
}

mat-sidenav-container{
	height: 100%;
}
.margin-left-right-auto {
  margin-left: auto;
  margin-right: auto;
}
.width-50 {
  width: 50%;
}
.gray-text {
  color: #999;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}

/*.mat-input-flex.mat-form-field-flex {
    border: 1px solid #ccc;
    padding: 8px;
}*/

.loginPanel .mat-dialog-container {
	padding: 0;
}

.smc-stylist-card{
	word-wrap: break-word;
}

@media (max-width: 960px){
	.smc-stylist-card{
		text-align: center !important;		
	}
}

.smr-error {
	color: red;
	margin-top: -20px;
}
.smr-error::before {
	content: "⚠️";
  display: inline-block;
}